import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import uuid4 from "uuid4";
import BlogCard from "../components/blog-card";
import Masonry from "react-masonry-css";

const breakpointColumnsObj = {
  default: 3,
  700: 2,
  500: 1,
};

const SearchResultPage = ({ data, location }) => {
  const query = new URLSearchParams(location.search).get("s");
  const searchText = query !== null ? query.toLowerCase() : "";
  const { site, allWpPost, allWpProject } = data;
  const [allSearchData, setAllSearchData] = useState([]);
  const siteUrl = site.siteMetadata.siteUrl;

  useEffect(() => {
    let postData = [];

    if (allWpPost && allWpPost.nodes.length > 0) {
      allWpPost.nodes
        .filter((item) => item.title.toLowerCase().includes(searchText))
        .map((item) => {
          postData.push({
            title: item.title,
            uri: item.uri,
            content: item.content,
            image: item.postFields.featureImage,
          });
        });
    }

    if (allWpProject && allWpProject.nodes.length > 0) {
      allWpProject.nodes
        .filter((item) => item.title.toLowerCase().includes(searchText))
        .map((item) => {
          postData.push({
            title: item.title,
            uri: item.uri,
            content: item.pageFieldsGroup.pageFieldsGroup[0].description,
            image: item.projectFields.projectFeatureImage,
          });
        });
    }

    setAllSearchData(postData);
  }, [query]);

    const breadcrumb = {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: "1",
          name: "Home",
          item: {
            url: `${siteUrl}`,
            id: `${siteUrl}`,
          },
        },
        {
          "@type": "ListItem",
          position: "2",
          name: `You searched for ${query}`,
          item: {
            url: `${siteUrl}`,
            id: `${siteUrl}`,
          },
        },
      ],
    };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>

      <GatsbySeo
        title={`You searched for kitchen - ${query}`}
        description={""}
        language="en"
        openGraph={{
          type: "article",
          url: `${siteUrl}/search/${query}`,
          title: `You searched for kitchen - ${query}`,
          description: '',
        }}
        twitter={{
          handle: "@amberthdesign",
          site: "@amberthdesign",
          cardType: "summary",
        }}
      />

      <div>
        <Layout>
          <section className={`my-5 my-md-6 my-xl-8`}>
            <Container>
              <Row className="justify-content-center">
                <Col lg={9} className={`text-center`}>
                  <h2 className="mb-0">search results for {query}</h2>
                </Col>
              </Row>
              <div className="mt-7">
              {allSearchData && allSearchData.length > 0 ? (
                  <Row className="g-40">
                    {allSearchData.map((item) => (
                      <BlogCard
                        title={item.title}
                        image={
                          item.image?.localFile.childImageSharp.gatsbyImageData
                        }
                        uri={item.uri}
                        excerpt={item.content}
                        imageAlt={item.image?.altText}
                        key={uuid4()}
                        textSize="fs-6"
                        showArrow={true}
                        isSearch={false}
                      />
                    ))}
                  </Row>
               
              ): (
                <p>Sorry, but nothing matched your search terms. Please try again with some different keywords.</p>
              )}
               </div>
            </Container>
          </section>
        </Layout>
      </div>
    </>
  );
};

export const pageQuery = graphql`
  query SearchQuery($s: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allWpPost(filter: { title: { regex: $s } }) {
      nodes {
        id
        title
        content
        uri
        postFields {
          featureImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
                original {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
    allWpProject(filter: { title: { regex: $s } }) {
      nodes {
        title
        uri
        projectFields {
          projectFeatureImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
                original {
                  width
                  height
                }
              }
            }
          }
        }
        pageFieldsGroup {
          pageFieldsGroup {
            ... on WpProject_Pagefieldsgroup_PageFieldsGroup_SectionLayout12 {
              description
              fieldGroupName
            }
          }
        }
      }
    }
  }
`;

export default SearchResultPage;
